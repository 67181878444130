/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Venise"), React.createElement("p", null, "Pour se loger, le ", React.createElement("a", {
    href: "https://www.booking.com/district/it/venice/grand-canal.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Grand Canal"), " partage en deux rives les six quartiers de Venise :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/venice/castello.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Castello"), " : quartier résidentiel tranquille."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/venice/cannaregio.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Cannaregio"), " : quartier des bars branchés et des hôtels abordables."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/venice/santacroce.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Santa Croce"), " : près de la gare Santa Lucia."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/venice/sanpolo.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "San Polo"), " : desservi par le pont du Rialto, nombreuses églises et restaurants."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/venice/sanmarco.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "San Marco"), " : quartier le plus central, autour de la place Saint Marc et du Palais des Doges."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/venice/dorsoduro.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Dorsoduro"), " : quartier animé, près de la Gallerie dell’Accademia et de la Collection Peggy Guggenheim."), "\n"), React.createElement("p", null, "Au sud de Dorsoduro, on trouve l’île de la ", React.createElement("a", {
    href: "https://www.booking.com/district/it/venice/giudecca.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Giudecca"), ".")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
